import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./About.scss";

class About extends Component {
  render() {
    const { mobile } = this.props;
    const postOverlapClass = mobile ? "post-overlap-mobile" : "post-overlap";

    return (
      <div className={`md-grid md-cell--9 post-page-contents mobile-fix ${postOverlapClass}`}>
        <Card className="md-grid md-cell--14 post">
          <div className="post-body">
           
            <CardText className="post-body">
              <h1 className="md-display-2 post-header">About / Impressum</h1>
              <p className="about-text md-body-1">{config.userDescription}</p>

              <h2 className="md-display-1 post-header">Impressum</h2>
              <div>
                <p>
                Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der in diesem Blog zur Verfügung gestellten Informationen kann nicht übernommen werden. Gleiches gilt auch für alle anderen Webseiten, auf die mittels Hyperlink verwiesen wird. Ich bin für den Inhalt der Webseiten, die aufgrund einer solchen Verbindung erreicht werden, nicht verantwortlich.  
                </p>
                <p>
                Angaben gemäß § 5 TMG und Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
                </p>
                <p>
                  <code>Hafid Haddouti – blog (aet) haddouti (do0ot) com</code>
                </p>
                <p>
                  
                </p>
              </div>
              
            </CardText>
            <UserLinks labeled config={config} />
          </div>
        </Card>
      </div>
    );
  }
}

export default About;
