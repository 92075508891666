import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import PostCover from "../components/PostCover";
import About from "../components/About";
import config from "../../data/SiteConfig";

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: true,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (window.innerWidth >= 640) {
      this.setState({ mobile: false });
    } else {
      this.setState({ mobile: true });
    }
  }
  
  render() {
    const { mobile } = this.state;
    const postNode = {cover: 'defaultCover.jpg'};
    const coverHeight = mobile ? 180 : 350;

    return (
      <Layout location={this.props.location} title={config.siteTitle + ": About"}>
        <div className="post-page md-grid md-grid--no-spacing">
          <Helmet>
            <title>{`About | ${config.siteTitle}`}</title>
            <link rel="canonical" href={`${config.siteUrl}/about/`} />
          </Helmet>
          <PostCover
            postNode={postNode}
            coverHeight={coverHeight}
            coverClassName="md-grid md-cell--9 post-cover"
          />
          <About />
        </div>
      </Layout>
    );
  }
}

export default AboutPage;
